import React, { memo, useEffect } from 'react'
import gsap from 'gsap'

export default function BackgroundGradient({ children, background, className }) {
    return (
        <div className='pos-rel overflow-hidden'>
            <div className={'pos-abs h-100p w-100 ' + className}>
                <Background>
                    {background}
                </Background>
            </div>
            {children}
        </div>
    )
}

const Background = memo(({ children }) => {
    useEffect(() => {
        gsap.to('.background-element.first', { opacity: 1, duration: 1, delay:0.5, ease: "linear" });
        var tl = gsap.timeline({repeat:-1, });
        tl.to('.background-element.first', { scale: 1.5, duration: 20, delay:1, ease: "linear" });
        tl.to('.background-element.first', { scale: 1, duration:20, ease: "linear" });
    }, [])
    return (
        <div className='bg-element-inner' >
            {children}
        </div>
    )
})


 