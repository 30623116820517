import React, { useEffect } from 'react'
import { useInView } from "react-intersection-observer"
import { useChangeMenuContext } from "../context/menuContext"

export default function ChangeMenuContextInView({ children }) {
    const { ref, inView } = useInView({ threshold: 0.7, initialInView: true });
    const setMenuState = useChangeMenuContext()
    useEffect(() => {
        setMenuState(!inView)
    }, [inView, setMenuState])

    return (
        <div ref={ref}>
            {children}
        </div>
    )
}
