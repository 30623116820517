import React, { memo, useEffect } from "react"
import scrollTo from "../utils/scrollTo"
import gsap from 'gsap'
import { useMenuState } from "../context/menuContext"
const HeroBanner = () => {
  useEffect(() => {
    gsap.to('.logo--hero', { opacity: 1, duration: 1, delay: .5, ease: "power3.inOut" });
    gsap.to('.scroll--button', { opacity: 1, duration: .75, delay: 1, ease: "power3.inOut" });
  }, [])

  const showMenu = useMenuState()

  useEffect(() => {

    gsap.to('.logo--hero', { opacity: (showMenu ? 0 : 1), duration: 0.325, ease: "power3.inOut" });

  }, [showMenu])


  return (
    <>
      <div className='hero--banner h-100 flex flex-wrap align-center justify-center pos-rel'>
        <div className={'logo--hero mta '}></div>

        <div className="flex align-center justify-center w-100 mb40 mta m-mb100 m-mta">
          <button onClick={() => scrollTo('#about')} className="link m-mb20 scroll--button unset-button flex  align-center justify-center"><div className="mouse" aria-label="Mouse" /></button>
        </div> 

      </div>
    </>
  )
}

export default memo(HeroBanner)
