import * as React from "react"
import Spacer from "../components/spacer"
import Link from "./link"
import { GatsbyImage } from "gatsby-plugin-image"

const Approach = ({ approaches }) => (
  <div id='approach' className='our--approach p40 bg-solid max-w-1600 mla mra '>
    <p className='m0 h1 fade--in' data-sal data-sal-delay="300">Our approach</p>
    <div className='grid mt40 m-grid-col-1'>
      {approaches.map((approach) => (
        <Card approach={approach} />
      ))}
    </div>
  </div >
)


function Card({ approach }) {
  const bgImage = approach?.image?.gatsbyImageData ? true : false;
  return (
    <Link to='/contact' state={{ subject: approach.link }} className='card b1 p40 flex flex-wrap pos-rel overflow-hidden border-box link  m-p30 fade--in' data-sal data-sal-delay="300">
      <div className="pos-rel z-2 flex flex-wrap ">
        <div className='w-100'>
          <p className={'m0 h3 large text-blue h-text-light'}>{approach.name}</p>
          <div className={'max-450 large mt40 mb40 p h-text-light'}>
            {approach.description}
          </div>
          <Spacer className="h-10vw " />
        </div>
        <div className='w-100 mta'>
          <div className={'button link  ' + (bgImage ? 'bg-solid ' : '')}>{approach.link}</div>
        </div>
      </div>
      <div className="bg-image z-1">
        <GatsbyImage objectFit="cover" image={approach?.image?.gatsbyImageData} />
      </div>
    </Link>
  )
}

export default Approach
