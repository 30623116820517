import * as React from "react"
import Layout from "../components/layout"
import HeroBanner from "../components/heroBanner"
import ScrollingText from "../components/scrollingText"
import Approach from "../components/approach"
import AboutUs from "../components/aboutUs"
import Services from "../components/services"
import Contact from "../components/contact"
import Spacer from "../components/spacer"
import Cta from "../components/cta"
import Seo from "../components/seo"
import BackgroundGradient from "../components/backgroundGradient"
import { StaticImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import MenuContext from "../context/menuContext"
import ChangeMenuContextInView from "../components/changeMenuContextInView"
import FadeOut from "../components/fadeOut"
const IndexPage = ({ data }) => {
  const { services, approach, ownerImage, gradient, aboutDescription, aboutTitle, servicesDescription } = data.homepage

  return (
    <MenuContext  >
      <Layout controlsEnabled={true}>
        <Seo title="Home" />

        <div className='parallax--banner'>
          <BackgroundGradient background={
            <div className='background-element first'>
              <div className='bg-element-inner'>
                <StaticImage placeholder="blurred" src='../images/background.png' />
              </div>
            </div>
          } >
            <HeroBanner />
          </BackgroundGradient>
        </div>
        <FadeOut>
          <ChangeMenuContextInView>
            <div style={{ height: '100vh' }} />
          </ChangeMenuContextInView>
          <AboutUs title={aboutTitle} description={aboutDescription} />
        </FadeOut>
        <div className='bg-grey'>
          <ScrollingText />
          <Spacer />
          <Approach approaches={approach} />
          <Spacer />
          <Services description={servicesDescription} services={services} />
          <Cta gradient={gradient} ownerImage={ownerImage} />
          <Contact contact={data.contact.edges[0].node} />
        </div>

      </Layout>
    </MenuContext >
  )
}

export default IndexPage

export const query = graphql`
  query HomePage {
    homepage:datoCmsHomePage {
      services {
        name
        icon {
          url
        }
      }
      approach {
        description:approachDescription
        name:approachName
        link:buttonText
        image:backgroundImage{
          gatsbyImageData
        }
      }
      servicesDescription
      aboutDescription
      aboutTitle
      gradient {
        gatsbyImageData
      }
      ownerImage {
        gatsbyImageData
      }
    }
    contact:allDatoCmsContactInformation {
      edges {
      node {
        email
        facebook
        hipages
        instagram
        phoneNumber
        licenses {
          license
        }
      }
    }
  }
  }
`
