import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import gsap from 'gsap'

export default function FadeOut({ children }) {
    const { ref, inView } = useInView({ threshold: [0.5], initialInView: false })

  

    return (
        <div ref={ref}>

            {children}
        </div>
    )
}
