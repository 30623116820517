import { useInView } from "react-intersection-observer"
import React, { useEffect, useState } from "react"
import BookQuote from "./bookQuote"
import Link from "./link"

const Contact = ({contact}) => {

  const { inView, ref } = useInView()

  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true)
    }, 0)
  }, [setLoaded])

  return (
    <>
      <div className="max-w-1600 mla mra">
        <BookQuote className={(loaded ? "" : 'no-transition hide ') + ' ' + (inView ? 'hide' : '')} />
      </div>
      <div ref={ref} className='contact--information p40 m-mt20 bg-solid  pb20  fade--in' data-sal data-sal-delay='300'>
        <div className='flex m-wrap bt1 pt60 m-pt40 max-w-1600 mla mra'>
          <div className='w-50 m-100 m-mb40'>
            <Link to='/' className='link inline'><div className='logo--footer'></div></Link>
          </div>
          <div className='max-550 mla w-100 flex m-pt20 m-flex m-flex-c'>
            <div className='w-50 m-w-100'>
              <div className='mb40'>
                <p className='strong m0 mb5'>Email</p>
                <a className='link op-50 ul-link black' href={'mailto:'+contact.email}>{contact.email}</a>
              </div>
              <div className='mb40'>
                <p className='strong m0 mb5'>Text or Call</p>
                <a className='link op-50 ul-link black' href={'tel:'+contact.phoneNumber}>{contact.phoneNumber}</a>
              </div>
              <div className='mb40'>
                <p className='strong m0 mb5'>License</p>
                <p className='m0 op-50'>  
                  {contact.licenses.map(({license}) => {
                    return (
                      <div>
                      {license}
                      </div>
                    )
                  })}
                  </p>
              </div>
            </div>
            <div className='w-50  m-w-100'>

              <div className='mb40'>
                <p className='strong m0 mb5'>Follow Us</p>
                <a className='link ul-link black op-50' href={contact.instagram} rel="noreferrer" target='_blank'>Instagram</a><br />
                <a className='link ul-link black op-50' href={contact.facebook} rel="noreferrer" target='_blank'>Facebook</a><br />
                <a className='link ul-link black op-50' href={contact.hipages} rel="noreferrer" target='_blank'>HiPages</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}

export default Contact
