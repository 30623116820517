import * as React from "react"
const AboutUs = ({ title, description }) => (
  <div id='about' className="bg-blur" >
    <div className='hero--banner  h-100 flex align-center max-w-1600 mla mra  mh-50'>
      <div className='max-700 p40 fade--in' data-sal data-sal-delay="300">
        <p className='bold m0'>About Us</p>
        <h1 className='m0 mt20 '>{title}</h1>
        <p className='m0 max-600 large mt40'>{description}</p>
      </div>
    </div>
  </div>
)

export default AboutUs
