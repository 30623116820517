import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import Spacer from "../components/spacer"
import Svg from 'react-inlinesvg'
const Services = ({ services, description }) => (

  <div id='services' className='our-services p40  pos-rel overflow-hidden  '>
 
    <div className='flex m-flex-c max-w-1600 mla mra pos-rel z-2'>
      <div className='w-50 pr40 m-w-100 m-mb100 '>
        <div className='fade--in' data-sal data-sal-delay="300">
          <p className='m0 h1'>Services</p>
          <div className='mt40 large max-550'>
            {description}
          </div>
        </div>
      </div>
      <div className='w-50 large pl40 m-w-100 m-pl0 list--in'data-sal data-sal-delay="300">
        {services.map((service) => {
          return (
            <p className='m0 flex align-center bb1 pb10 pt10 list-item'>
              <Svg height={30} width={30} className='mr20' src={service.icon.url} />
              {service.name}
            </p>
          )
        })}
      </div>
    </div>
    <Spacer />

   
  </div>
)

export default Services
