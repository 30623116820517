import React, { useEffect } from "react"
import gsap from "gsap"
const ScrollingText = () => {

  useEffect(() => {
    const tl = gsap.timeline({ repeat: -1 })
    tl.fromTo('.scrolling--text .inner', { x: '0%' }, { x: "-50%", duration: 20, ease: 'linear' })
  }, [])
  return (
    <>
      <div className='scrolling--text bg-solid pt40 pb40 flex fade--in' data-sal data-sal-delay="300">
        <div className='inner flex'>
          <p className='h0 m0'>New Builds <span className='gradient-circle' /> Renovations <span className='gradient-circle two' /> Maintenance </p>  <span className="h0"> &nbsp;</span> <span className='gradient-circle three' />
          <span className="h0"> &nbsp;</span>
          <p className='h0 m0 '>New Builds <span className='gradient-circle' /> Renovations <span className='gradient-circle two' /> Maintenance </p>  <span className="h0"> &nbsp;</span> <span className='gradient-circle three' />
        </div>
      </div>
      <div className='pl40 pr40 max-w-1600 mla mra m-pl20 m-pr20'>
        <div className='bt1' />
      </div>
    </>
  )


}

export default ScrollingText
