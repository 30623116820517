import * as React from "react"
import Spacer from "../components/spacer"
import Link from "./link"
import { GatsbyImage } from "gatsby-plugin-image"

const CTA = ({ ownerImage, gradient }) => (
  <div className="max-w-1600 mla mra p40  m-mb10 m-pb0">
    <div className='cta  bg-solid  bt1 pb20 m-pb0   '>
      <Spacer />
      <div className='flex align-center m-flex-c fade--in ' data-sal data-sal-delay='300'>
        <div className='w-50 flex m-w-100 m-mb75'>
          <div className='text-center m-w-100 w-100'>
            <div className='circle-profile  z-2 pos-rel' >
              <div className="bg-image">
                <GatsbyImage image={ownerImage?.gatsbyImageData} />
              </div>
            </div>
            <div className='circle-profile mln25 z-1 pos-rel' >
              <div className="bg-image">
                <GatsbyImage objectFit="cover" image={gradient?.gatsbyImageData} />
              </div>
            </div>
          </div>
        </div>
        <div className='w-50 m-w-100 m-flex m-justify-center m-flex-c align-center  '>
          <p className='h1 m0 max-600 m-text-center '>Schedule a complimentary on-site or video quote.</p>
          <div className="flex  ">
            <Link state={{ subject: 'Free quote' }} to='/contact' className='button link mt40 hover-state'>Get a free quote</Link>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default CTA
